import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const XPMessenger = () => {
  const [alias, setAlias] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [canSendMessage, setCanSendMessage] = useState(true);
  const messagesEndRef = useRef(null);
  const restrictedUsernames = ['admin', 'dev', 'announcement', 'team', 'moderator', 'system'];

  useEffect(() => {
    if (isConnected) {
      fetchMessages();
      const interval = setInterval(fetchMessages, 5000);
      return () => clearInterval(interval);
    }
  }, [isConnected]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async () => {
    try {
      const response = await fetch('messenger.php');
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  
  // const handleConnect = () => {
  //   if (alias.trim()) {
  //     setIsConnected(true);
  //   }
  // };
  const handleConnect = () => {
    const trimmedAlias = alias.trim().toLowerCase();
    if (trimmedAlias && !restrictedUsernames.includes(trimmedAlias)) {
      setIsConnected(true);
    } else {
      alert('Please choose a different username. This one is not allowed.');
    }
  };
  
  const handleSendMessage = async () => {
    if (message.trim() && canSendMessage) {
      setCanSendMessage(false);
      try {
        const response = await fetch('messenger.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ alias, message }),
        });
        if (response.ok) {
          setMessage('');
          fetchMessages();
          setTimeout(() => setCanSendMessage(true), 5000);
        } else {
          console.error('Failed to send message');
          setCanSendMessage(true);
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setCanSendMessage(true);
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MessengerContainer>
      {!isConnected ? (
        <LoginContainer>
          <LoginBox>
            <LoginTitle>Sign In</LoginTitle>
            <LoginInput
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              placeholder="Enter your alias"
            />
            <LoginButton onClick={handleConnect}>Sign In</LoginButton>
          </LoginBox>
        </LoginContainer>
      ) : (
        <ChatContainer>
          <ChatArea>
            <MessageList>
              {messages.map((msg, index) => (
                <MessageItem key={index} isOwnMessage={msg.alias === alias}>
                  <MessageAlias>{msg.alias}:</MessageAlias>
                  <MessageContent>{msg.message}</MessageContent>
                </MessageItem>
              ))}
              <div ref={messagesEndRef} />
            </MessageList>
            <InputArea>
              <MessageInput
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message"
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                disabled={!canSendMessage}
              />
              <SendButton onClick={handleSendMessage} disabled={!canSendMessage}>
                Send
              </SendButton>
            </InputArea>
          </ChatArea>
        </ChatContainer>
      )}
    </MessengerContainer>
  );
};
// Styled components remain the same as in the previous example, 
// except for the removal of Sidebar, SidebarTitle, OnlineList, and OnlineUser
const MessengerContainer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  border: 1px solid #0054e3;
  border-radius: 3px;
  box-shadow: inset 1px 1px 0px white, inset -1px -1px 0px #707070;
  font-family: 'Tahoma', sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MessengerHeader = styled.div`
  background: linear-gradient(to right, #0054e3 0%, #2e8ae6 100%);
  color: white;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const HeaderButtons = styled.div`
  display: flex;
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
`;

const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 15px;
  color: #0054e3;
`;

const LoginInput = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #7f9db9;
  font-size: 14px;
  margin-bottom: 10px;
`;

const LoginButton = styled.button`
  width: 100%;
  background: linear-gradient(to bottom, #f0f0ea 0%, #e1e1d5 100%);
  border: 1px solid #707070;
  border-radius: 3px;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: linear-gradient(to bottom, #e1e1d5 0%, #f0f0ea 100%);
  }

  &:active {
    background: #e1e1d5;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
`;

const Sidebar = styled.div`
  width: 200px;
  background: #f0f0f0;
  border-right: 1px solid #7f9db9;
  display: flex;
  flex-direction: column;
`;

const SidebarTitle = styled.div`
  background: #e1e1e1;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #7f9db9;
`;

const OnlineList = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const OnlineUser = styled.div`
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background: #e1e1e1;
  }
`;

const LoginBox = styled.div`
  background: white;
  border: 1px solid #7f9db9;
  padding: 20px;
  width: 80%;
  max-width: 300px;
`;

const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: column;
`;

const MessageItem = styled.div`
  margin-bottom: 5px;
  text-align: ${props => props.isOwnMessage ? 'right' : 'left'};
  word-wrap: break-word;
  max-width: 80%;
  align-self: ${props => props.isOwnMessage ? 'flex-end' : 'flex-start'};
`;

const MessageAlias = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #0054e3;
`;

const MessageContent = styled.span`
  font-size: 12px;
  margin-left: 5px;
`;

const InputArea = styled.div`
  display: flex;
  padding: 10px;
  background: #f0f0f0;
  border-top: 1px solid #7f9db9;
`;

const MessageInput = styled.input`
  flex: 1;
  padding: 5px;
  border: 1px solid #7f9db9;
  font-size: 12px;
  min-width: 0;
`;

const SendButton = styled.button`
  background: linear-gradient(to bottom, #f0f0ea 0%, #e1e1d5 100%);
  border: 1px solid #707070;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
`;

const ChatContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const ChatArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export default XPMessenger;