import { useEthersProvider } from 'hooks/useEthers';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { Helper } from "./helper";
import { defaultTokens, WETH } from './config';
import { createWalletClient, custom } from 'viem';
import { BigNumber, providers, utils} from 'ethers';

export default function XPDefiSwap(props) {
  // console.log('XPDefiSwap props:', props);
  const [activeTab, setActiveTab] = useState('wallet');
  const [ciframe, setCiframe] = useState(<>Select a token in the swap menu to see it's Chart you'll see the chart of the token you are trading from</>);
  const [activeLiquidityTab, setActiveLiquidityTab] = useState('add');
  const [fromToken, setFromToken] = useState('');
  const [toToken, setToToken] = useState('');
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [fromBalance, setFromBalance] = useState('0');
  const [toBalance, setToBalance] = useState('0');
  const [slippageTolerance, setSlippageTolerance] = useState('100');
  const [deadline, setDeadline] = useState('20');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [tokenDetails, setTokenDetails] = useState(null);
  const [swapMessage, setSwapMessage] = useState('');
  const [pairs, setPairs] = useState([]);

  // Liquidity states
  const [liquidityToken1, setLiquidityToken1] = useState('');
  const [liquidityToken2, setLiquidityToken2] = useState('');
  const [liquidityAmount1, setLiquidityAmount1] = useState('');
  const [liquidityAmount2, setLiquidityAmount2] = useState('');

  // Launcher states
  const [launcherToken1, setLauncherToken1] = useState('');
  const [launcherToken2, setLauncherToken2] = useState('');
  const [liqFee, setLiqFee] = useState('');
  const [buyFee, setBuyFee] = useState('');
  const [sellFee, setSellFee] = useState('');
  const [devFee, setDevFee] = useState('');
  const [devAddress, setDevAddress] = useState('');

  const [helper, setHelper] = useState(null);

  const { address, isConnected } = useAccount()
  const { connect } = useConnect({ connector: new InjectedConnector(), })
  const { disconnect } = useDisconnect()

  const openModal = selection => {
    setCurrentSelection(selection);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (activeTab === 'wallet') {
    }
     else {
      if (!isConnected) {
        setActiveTab('wallet')
      }
    }
  }, [activeTab, isConnected])

  useEffect(() => {
    if (!etherProvider) return;
    if (!helper) return;
    if (!fromToken) return;
    // setCiframe(<>Loading chart for {fromToken.symbol}...</>);
    helper.getChart(fromToken).then((iframe) => {
      // get href from iframe and log
      console.log("Iframe:",iframe);
      setCiframe(iframe);
    });
  }, [fromToken, etherProvider, helper])

  let etherProvider = useEthersProvider();

  useEffect(() => {
    // let helper = new Helper(etherProvider);
    // setHelper(helper);
    if (!address) return;
    const client0 = createWalletClient({
      transport: custom(window.ethereum),
    });
    
    const provider = new providers.Web3Provider(client0.transport, 'any');
    const helper = new Helper(provider);
    setHelper(helper);
    window.helper = helper;
    props.setBalloonTrigger("Wallet Connected");
    helper.getLatestPairsIn24Hours().then((pairs) => {
      setPairs(pairs);
      // automatically run getLatestPairsIn24Hours every 1 min
    });
    setInterval(() => {
      helper.getLatestPairsIn24Hours().then((pairs) => {
        setPairs(pairs);
      });
    }
    , 60000);
    setActiveTab('swap');
  }, [address])

  useEffect(() => {
    if (!helper || !fromToken || !address) return;
    helper.getBalance(fromToken, address).then(balance => {
      setFromBalance(balance);
    });
  }, [helper, fromToken, address]);

  useEffect(() => {
    if (!helper || !toToken || !address) return;
    helper.getBalance(toToken, address).then(balance => {
      setToBalance(balance);
    });
  }, [helper, toToken, address]);

  useEffect(() => {
    if (!helper) return;
    if (!fromAmount) {setToAmount('');return;}
    let fromAmount1;
    try{
      // fromAmount1 = BigNumber.from(fromAmount.endsWith('.') ? fromAmount.slice(0, -1) : fromAmount).mul(BigNumber.from(10).pow(fromToken.decimals)).toString();
      fromAmount1 = utils.parseUnits(fromAmount, fromToken.decimals).toString();
    }catch(e){
      console.log(e);
    }
    helper.getAmountsOut(fromToken, toToken, fromAmount1).then(amount => {
      setToAmount(amount);
      }
    ).catch(e => {
      console.log(e);
      setToAmount('');
    })
  }, [fromAmount, fromToken, toToken, helper]);

  useEffect(() => {
    connect();
  }, []);

  const sanitizeAmountInput = (input) => {
    return input.replace(/[^0-9.]/g, '');
  };

  const selectToken = (name, symbol, decimals,address) => {
    const tokenData = { name, symbol, decimals, address };
    if (currentSelection === 'from') setFromToken(tokenData);
    else if (currentSelection === 'to') setToToken(tokenData);
    else if (currentSelection === 'liq1') setLiquidityToken1(tokenData);
    else if (currentSelection === 'liq2') setLiquidityToken2(tokenData);
    else if (currentSelection === 'launcher1') setLauncherToken1(tokenData);
    else if (currentSelection === 'launcher2') setLauncherToken2(tokenData);
    closeModal();
  };

  const swapTokenPositions = () => {
    let tempToken = toToken;
    let tempAmount = toAmount;
    let tempBalance = toBalance;
    setToToken(fromToken);
    setToAmount(fromAmount);
    setToBalance(fromBalance);
    setFromToken(tempToken);
    setFromAmount(tempAmount);
    setFromBalance(tempBalance);
  };

  const handleSwap = async () => {
    // console.log('Swap details:', {
    //   fromToken,
    //   toToken,
    //   fromAmount,
    //   toAmount,
    //   slippageTolerance,
    //   deadline,
    // });
    if (!helper) return;
    if (!fromToken || !toToken || !fromAmount) return;
    if (fromToken.address === toToken.address) return;
    // setSwapMessage('<div style={{color: "green"}}>Swapping...</div>');
    props.setBalloonTrigger("Swapping...::::Please wait");
    try{
      let out = await helper.swapTokens(fromToken, toToken, utils.parseUnits(fromAmount, fromToken.decimals), slippageTolerance);
      // setSwapMessage(`<div style={{color: 'green'}}>Transaction hash: <a href='https
      //   ://etherscan.io/tx/${out.hash}' target="_blank>${out.hash}</a></div>`);
        setSwapMessage(`<a href='https://etherscan.io/tx/${out.hash}' target="_blank">Transaction Hash</a>`);
        props.setBalloonTrigger(`Transaction Sent`);
        // wait for tx
        await out.wait();
        setSwapMessage(`<div style={{color: 'green'}}>Transaction successful</div>`);
        props.setBalloonTrigger(`Transaction Successful`);
        // wait for 5 seconds and clear the message
        setTimeout(() => {
          setSwapMessage('');
        }, 5000);
    }catch(e){
      console.log(e);
      let message;
      try{
        message = JSON.parse(e.message.toString());
        console.log(message);
      }catch{
        message = e
      }
      if (message.message.toString().includes('rejected')){
        // try parsing the message in json
        props.setBalloonTrigger(`Transaction Rejected`);
        // props.setBalloonTrigger(`Error: ${message.message.toString()}`);
      }else if (e.message.toString().includes('insufficient')){
        props.setBalloonTrigger(`Insufficient Balance / Gas to make transaction`);
        // props.setBalloonTrigger(`Error: ${message.message.toString()}`);
      }else{
        // read message from error
        props.setBalloonTrigger(`Error: ${message.message.toString()}`);
      }
    }

  };

  const handleAddLiquidity = () => {
    console.log('Add Liquidity details:', {
      token1: liquidityToken1,
      token2: liquidityToken2,
      amount1: liquidityAmount1,
      amount2: liquidityAmount2,
      slippageTolerance,
      deadline,
    });
  };

  const handleMaxAmount = () => {
    setFromAmount(sanitizeAmountInput(fromBalance.toString()));
  };

  const handleTokenAddressChange = async (e) => {
    setTokenAddress(e.target.value);
    if (helper) {
      try {
        const details = await helper.getTokenDetails(e.target.value);
        setTokenDetails(details);
      } catch (error) {
        setTokenDetails(null);
      }
    }
  };

  return (
    <SwapContainer>
      {/* <SwapHeader>XP.network DeFi</SwapHeader> */}
      <TabContainer>
        <Tab active={activeTab === 'swap'} onClick={() => setActiveTab('swap')}>Swap</Tab>
        <Tab active={activeTab === 'chart'} onClick={() => setActiveTab('chart')}>Chart</Tab>
        <Tab active={activeTab === 'pair'} onClick={() => setActiveTab('pair')}>Pair Explorer</Tab>
        {/* <Tab active={activeTab === 'launcher'} onClick={() => setActiveTab('launcher')}>Launcher</Tab> */}
        <Tab active={activeTab === 'rewards'} onClick={() => setActiveTab('rewards')}>Rewards</Tab>
        <Tab active={activeTab === 'wallet'} onClick={() => setActiveTab('wallet')}>Wallet</Tab>
        
      </TabContainer>

      <ContentArea>
        {activeTab === 'swap' && (
          <>
            <SwapBox>
              <Label>From:</Label>
              <TokenInputWrapper>
                <TokenInput
                  type="text"
                  value={fromAmount}
                  onChange={(e) => setFromAmount(sanitizeAmountInput(e.target.value))}
                  placeholder="Enter token amount"
                />
                <SelectToken onClick={() => openModal('from')}>
                  {fromToken.symbol || 'Select token'}
                </SelectToken>
              </TokenInputWrapper>
              <BalanceDisplay>Balance: {fromBalance} <MaxButton onClick={handleMaxAmount}>Max</MaxButton></BalanceDisplay> 

              <SwapArrow onClick={swapTokenPositions}>↕️</SwapArrow>

              <Label>To:</Label>
              <TokenInputWrapper>
                <TokenInput
                  type="text"
                  value={toAmount}
                  onChange={(e) => setToAmount(sanitizeAmountInput(e.target.value))}
                  placeholder="Enter token amount"
                />
                <SelectToken onClick={() => openModal('to')}>
                  {toToken.symbol || 'Select token'}
                </SelectToken>
              </TokenInputWrapper>
              <BalanceDisplay>Balance: {toBalance} </BalanceDisplay> 

              <Label>Slippage Tolerance (%):</Label>
              <TokenInput
                type="text"
                value={slippageTolerance}
                onChange={(e) => setSlippageTolerance(e.target.value)}
                placeholder="Enter slippage tolerance"
              />

              <Label>Transaction Deadline (minutes):</Label>
              <TokenInput
                type="text"
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                placeholder="Enter transaction deadline"
              />
              <SwapButton onClick={handleSwap} style={{marginTop: '10px'}}>Swap</SwapButton>
              {/* <SwapButton onClick={()=>{props.setBalloonTrigger(`Test${Date.now()}`)}} style={{marginTop: '10px'}}>Swap</SwapButton> */}
            </SwapBox>
            <div dangerouslySetInnerHTML={{ __html: swapMessage }}/>
          </>
        )}

        {activeTab === 'pair' && (
    //       `[
    // {
    //     "createdAt": 1725312503,
    //     "lastTransaction": 1725312503,
    //     "exchange": {
    //         "address": "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f",
    //         "id": "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f:1",
    //         "name": "Uniswap",
    //         "exchangeVersion": null,
    //         "networkId": 1,
    //         "tradeUrl": "https://app.uniswap.org/#/swap"
    //     },
    //     "liquidity": "4092",
    //     "liquidityToken": "token0",
    //     "quoteToken": "token1",
    //     "marketCap": "13146.48430471584",
    //     "price": "0.000000189376034352",
    //     "pair": {
    //         "address": "0x066eb289e0ca6748f4fb279fdd6aebd37ad03d57",
    //         "exchangeHash": "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f",
    //         "fee": null,
    //         "id": "0x066eb289e0ca6748f4fb279fdd6aebd37ad03d57:1",
    //         "networkId": 1,
    //         "tickSpacing": null,
    //         "token0": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    //         "token1": "0xfc7cb5d4c0f68f7c937d67224dc1fff722d5aa93"
    //     },
    //     "token0": {
    //         "address": "0xfc7cb5d4c0f68f7c937d67224dc1fff722d5aa93",
    //         "id": "0xfc7cb5d4c0f68f7c937d67224dc1fff722d5aa93:1",
    //         "name": "UNICORN LLAMA | Vitalik Favourite Animal",
    //         "networkId": 1,
    //         "symbol": "LLAMA",
    //         "totalSupply": "69420000000",
    //         "pooled": "21605553484.674918539",
    //         "decimals": 9
    //     },
    //     "token1": {
    //         "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    //         "id": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2:1",
    //         "name": "Wrapped Ether",
    //         "networkId": 1,
    //         "symbol": "WETH",
    //         "totalSupply": "2911067.586412464376053285",
    //         "pooled": "1.606674287528149791",
    //         "decimals": 18
    //     },
    //     "buyCount": 23,
    //     "highPrice": "0.000000189376034352",
    //     "lowPrice": "0.0000000435906837032",
    //     "priceChange": "3.344415326022929",
    //     "sellCount": 0,
    //     "txnCount": 23,
    //     "uniqueBuys": 23,
    //     "uniqueSells": 0,
    //     "uniqueTransactions": 23,
    //     "volumeUSD": "2131"
    // },]` //example pair show that data in a list table with buttons to buy for each pair
          <>
            {pairs.length === 0 && (
              <div>Fetching newly deployed pairs</div>
            )}
            {pairs.map((pair, index) => (
              <SwapBox key={index}>
                <Label>Pair {index + 1}:</Label>
                <div>Token 1: {pair.token0.name} ({pair.token0.symbol})</div>
                <div>Token 2: {pair.token1.name} ({pair.token1.symbol})</div>
                <div>Price: {pair.price}</div>
                <div>Volume: {pair.volumeUSD}</div>
                <div>Market Cap: {pair.marketCap}</div>
                <SwapButton onClick={()=>{
                  let token1 = {name: pair.token0.name, symbol: pair.token0.symbol, decimals: pair.token0.decimals, address: pair.token0.address};
                  let token2 = {name: pair.token1.name, symbol: pair.token1.symbol, decimals: pair.token1.decimals, address: pair.token1.address};
                  if (token1.address.toLowerCase() === WETH.toLowerCase() || token2.address.toLowerCase() === WETH.toLowerCase()){
                    if (token1.address.toLowerCase() === WETH.toLowerCase()){
                      token1 = {name: 'ETH', symbol: 'ETH', decimals: 18, address: ''}
                      setFromToken(token1);
                      setToToken(token2);
                    }
                    else{
                      token2 = {name: 'ETH', symbol: 'ETH', decimals: 18, address: ''}
                      setFromToken(token2);
                      setToToken(token1);
                    }
                  }else{
                    setFromToken(token1);
                    setToToken(token2);
                  }
                  setActiveTab('swap');
                }}>Buy</SwapButton>
              </SwapBox>
            ))}
          </>
        )}

        {activeTab === 'chart' && (
          <>
            {ciframe}
          </>
        )}

        {activeTab === 'launcher' && (
          <>
            <Label>Token 1:</Label>
            <TokenInput
              type="text"
              value={launcherToken1}
              onChange={(e) => setLauncherToken1(e.target.value)}
              placeholder="Enter token amount"
            />
            <SelectToken onClick={() => openModal('launcher1')}>Select token</SelectToken>

            <Label>Token 2:</Label>
            <TokenInput
              type="text"
              value={launcherToken2}
              onChange={(e) => setLauncherToken2(e.target.value)}
              placeholder="Enter token amount"
            />
            <SelectToken onClick={() => openModal('launcher2')}>Select token</SelectToken>

            <Label>Fees:</Label>
            <TokenInput
              type="text"
              value={liqFee}
              onChange={(e) => setLiqFee(e.target.value)}
              placeholder="Liquidity Fee (%)"
            />
            <TokenInput
              type="text"
              value={buyFee}
              onChange={(e) => setBuyFee(e.target.value)}
              placeholder="Buy Fee (%)"
            />
            <TokenInput
              type="text"
              value={sellFee}
              onChange={(e) => setSellFee(e.target.value)}
              placeholder="Sell Fee (%)"
            />
            <TokenInput
              type="text"
              value={devFee}
              onChange={(e) => setDevFee(e.target.value)}
              placeholder="Dev Fee (%)"
            />
            <TokenInput
              type="text"
              value={devAddress}
              onChange={(e) => setDevAddress(e.target.value)}
              placeholder="Dev Address"
            />

            <SwapButton>Launch</SwapButton>
          </>
        )}

        {activeTab === 'rewards' && (
          <div>Rewards functionality coming soon...</div>
        )}

        {activeTab === 'wallet' && (
          <div>
            {isConnected ? (
              <>
                <p>Connected with address: {address}</p>
                <SwapButton onClick={() => disconnect()}>Disconnect</SwapButton>
              </>
            ) : (
              <SwapButton onClick={() => connect()}>Connect Wallet</SwapButton>
            )}
          </div>
        )}
      </ContentArea>

      {isModalOpen && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <span>Select Token</span>
              <span onClick={closeModal} style={{ cursor: 'pointer' }}>×</span>
            </ModalHeader>
            <ModalBody>
              <ModalInput
                type="text"
                value={tokenAddress}
                onChange={handleTokenAddressChange}
                placeholder="Enter token address"
              />
              {tokenDetails ? (
                <TokenOption onClick={() => selectToken(tokenDetails.name, tokenDetails.symbol, tokenDetails.decimals, tokenAddress)}>
                  {tokenDetails.name} ({tokenDetails.symbol})
                </TokenOption>
              ) : tokenAddress && (
                <div>No token found</div>
              )}
              {/* Replace this with your actual token list */}
              <TokenOption onClick={() => selectToken('Ethereum', 'ETH', 18, '')}>
                ETH - Ethereum
              </TokenOption>
              <TokenOption onClick={() => selectToken('Tether', 'USDT', 6, '0xdAC17F958D2ee523a2206206994597C13D831ec7')}>
                USDT - Tether
              </TokenOption>
              <ModalButtonGroup>
                <ModalButton onClick={closeModal}>Cancel</ModalButton>
              </ModalButtonGroup>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SwapContainer>
  );
}

const SwapContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  border: 1px solid #0054e3;
  border-radius: 3px;
  box-shadow: inset 1px 1px 0px white, inset -1px -1px 0px #707070;
  font-family: 'Tahoma', sans-serif;
  box-sizing: border-box;
  overflow: hidden;
`;

const SwapHeader = styled.h2`
  background: linear-gradient(to right, #0054e3 0%, #2e8ae6 100%);
  color: white;
  padding: 5px 10px;
  margin: -10px -10px 10px -10px;
  font-size: 14px;
  font-weight: bold;
`;

const ContentArea = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
`;

const SwapBox = styled.div`
  background: white;
  border: 1px solid #7f9db9;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
`;

const TokenInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TokenInput = styled.input`
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #7f9db9;
  font-size: 12px;
`;

const SelectToken = styled.span`
  font-size: 10px;
  color: #0054e3;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const SwapArrow = styled.div`
  text-align: center;
  font-size: 20px;
  margin: 10px 0;
  color: #0054e3;
`;

const SwapButton = styled.button`
  width: 100%;
  background: linear-gradient(to bottom, #f0f0ea 0%, #e1e1d5 100%);
  border: 1px solid #707070;
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to bottom, #e1e1d5 0%, #f0f0ea 100%);
  }
  &:active {
    background: #e1e1d5;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  border: 1px solid #0054e3;
  border-radius: 3px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  width: 300px;
`;

const ModalHeader = styled.div`
  background: linear-gradient(to right, #0054e3 0%, #2e8ae6 100%);
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div`
  padding: 15px;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #7f9db9;
  font-size: 12px;
  box-sizing: border-box;
`;

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

const ModalButton = styled.button`
  background: linear-gradient(to bottom, #f0f0ea 0%, #e1e1d5 100%);
  border: 1px solid #707070;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to bottom, #e1e1d5 0%, #f0f0ea 100%);
  }
  &:active {
    background: #e1e1d5;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Tab = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  background: ${props => props.active ? 'linear-gradient(to bottom, #f0f0ea 0%, #e1e1d5 100%)' : 'transparent'};
  border: 1px solid #707070;
  border-bottom: ${props => (props.active ? 'none' : '1px solid #707070')};
  border-radius: 3px 3px 0 0;
  font-size: 12px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

const SubTabContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SubTab = styled(Tab)`
  font-size: 11px;
`;

const TokenOption = styled.div`
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const BalanceDisplay = styled.div`
  font-size: 10px;
  color: #666;
  margin-top: 2px;
`;

const MaxButton = styled.button`
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 10px;
  background: #f0f0ea;
  border: 1px solid #707070;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: #e1e1d5;
  }
`;