// from Webamp demo

const album = 'EtherXP';

export const initialTracks = [
  {
    url:
      '/sounds/EtherXP.mp3',
    duration: 131,
    metaData: {
      title: 'EtherXP Official Song',
      artist: 'Your Friendly Dev',
      album,
    },
  },
];
