import React from 'react';

import WinXP from 'WinXP';

import { WagmiConfig, createConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains';
import { createPublicClient, http } from 'viem'
 
const config = createConfig({
  autoConnect: false,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http()
  }),
})

const App = () => {
  
  return (
    <>
    <WagmiConfig config={config}>
      <WinXP />
    </WagmiConfig>
    </>
  );
};

export default App;
