import univ2Abi from 'assets/abis/univ2router.json';
import erc20Abi from 'assets/abis/erc20.json';

export const defaultTokens = [
    {
        name: 'ETH',
        symbol: 'ETH',
        address: '',
        decimals: 18,
    },
    {
        name: 'USDT',
        symbol: 'USDT',
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6,
    }
]

export const WETH = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";