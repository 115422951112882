import React, { useState } from 'react';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import { WindowDropDowns, Google } from 'components';
import dropDownData from './dropDownData';
import ie from 'assets/windowsIcons/ie-paper.png';
import printer from 'assets/windowsIcons/17(32x32).png';
import go from 'assets/windowsIcons/290.png';
import links from 'assets/windowsIcons/links.png';
import search from 'assets/windowsIcons/299(32x32).png';
import favorite from 'assets/windowsIcons/744(32x32).png';
import back from 'assets/windowsIcons/back.png';
import earth from 'assets/windowsIcons/earth.png';
import edit from 'assets/windowsIcons/edit.png';
import forward from 'assets/windowsIcons/forward.png';
import history from 'assets/windowsIcons/history.png';
import home from 'assets/windowsIcons/home.png';
import mail from 'assets/windowsIcons/mail.png';
import msn from 'assets/windowsIcons/msn.png';
import refresh from 'assets/windowsIcons/refresh.png';
import stop from 'assets/windowsIcons/stop.png';
import windows from 'assets/windowsIcons/windows.png';
import dropdown from 'assets/windowsIcons/dropdown.png';

function InternetExplorer({ onClose }) {
  const [state, setState] = useState({
    url: 'https://dexscreener.com/',
    history: ['https://oldgoogle.neocities.org/2010/search/?scope=search-site&q='],
    currentIndex: 0,
  });

  function navigate(url) {
    setState(prevState => ({
      ...prevState,
      url,
      history: [...prevState.history.slice(0, prevState.currentIndex + 1), url],
      currentIndex: prevState.currentIndex + 1,
    }));
  }

  function goBack() {
    if (state.currentIndex > 0) {
      setState(prevState => ({
        ...prevState,
        currentIndex: prevState.currentIndex - 1,
        url: prevState.history[prevState.currentIndex - 1],
      }));
    }
  }

  function goForward() {
    if (state.currentIndex < state.history.length - 1) {
      setState(prevState => ({
        ...prevState,
        currentIndex: prevState.currentIndex + 1,
        url: prevState.history[prevState.currentIndex + 1],
      }));
    }
  }

  function refresh() {
    setState(prevState => ({ ...prevState }));
  }

  function goHome() {
    navigate('https://oldgoogle.neocities.org/2010/search/?scope=search-site&q=');
  }

  function onClickOptionItem(item) {
    switch (item) {
      case 'Close':
        onClose();
        break;
      case 'Back':
        goBack();
        break;
      case 'Forward':
        goForward();
        break;
      case 'Refresh':
        refresh();
        break;
      case 'Home Page':
        goHome();
        break;
      default:
    }
  }

  return (
    <Wrapper>
      <section className="ie__toolbar">
        <WindowDropDowns items={dropDownData} onClickItem={onClickOptionItem} />
      </section>
      <section className="ie__function_bar">
        <div className="ie__function_bar__button" onClick={goBack}>
          <img className="ie__function_bar__icon" src={back} alt="Back" />
          <span>Back</span>
        </div>
        <div className="ie__function_bar__button" onClick={goForward}>
          <img className="ie__function_bar__icon" src={forward} alt="Forward" />
          <span>Forward</span>
        </div>
        <div className="ie__function_bar__button" onClick={refresh}>
          <img className="ie__function_bar__icon" src={stop} alt="Stop" />
          <span>Stop</span>
        </div>
        <div className="ie__function_bar__button" onClick={refresh}>
          <img className="ie__function_bar__icon" src={refresh} alt="Refresh" />
          <span>Refresh</span>
        </div>
        <div className="ie__function_bar__button" onClick={goHome}>
          <img className="ie__function_bar__icon" src={home} alt="Home" />
          <span>Home</span>
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon" src={search} alt="Search" />
          <span>Search</span>
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon" src={favorite} alt="Favorites" />
          <span>Favorites</span>
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon" src={history} alt="History" />
          <span>History</span>
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon" src={mail} alt="Mail" />
          <span>Mail</span>
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon" src={printer} alt="Print" />
          <span>Print</span>
        </div>
      </section>
      <AddressBar>
        <div className="ie__address_bar__title">Address</div>
        <div className="ie__address_bar__content">
          <img src={ie} alt="ie" className="ie__address_bar__ie_icon" />
          <input
            type="text"
            // if url is oldgoogle.neocities.org, then show https://google.com as value
            value={state.url.includes('oldgoogle.neocities.org') ? 'https://google.com' : state.url}
            onChange={(e) => setState(prevState => ({ ...prevState, url: e.target.value }))}
            onKeyPress={(e) => e.key === 'Enter' && navigate(e.target.value)}
          />
          <img src={go} alt="go" onClick={() => navigate(state.url)} />
        </div>
        <div className="ie__address_bar__links">
          <img src={links} alt="links" />
          <span>Links</span>
          <img src={dropdown} alt="dropdown" />
        </div>
      </AddressBar>
      {/* <IFrameWrapper>
        <Iframe url={state.url}
          width="100%"
          height="100%"
          id="ie-iframe"
          className="ie-iframe"
          display="block"
          position="relative"/>
      </IFrameWrapper> */}
      <ObjectWrapper>
        <object
          type="text/html"
          data={state.url}
          width="100%"
          height="100%"
        />
      </ObjectWrapper>
      <Footer>
        <div className="ie__footer__status">
          <img src={ie} alt="ie" />
          <span>Done</span>
        </div>
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__right">
          <img src={windows} alt="windows" />
          <img src={msn} alt="msn" />
          <img src={earth} alt="earth" />
        </div>
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);

  .ie__toolbar {
    height: 24px;
    background-color: #fff;
  }

  .ie__function_bar {
    display: flex;
    height: 36px;
    align-items: center;
    font-size: 11px;
    padding: 1px 3px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &__button {
      display: flex;
      height: 100%;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 3px;
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
      }
      &:hover:active {
        border: 1px solid rgb(185, 185, 185);
        background-color: #dadada;
        box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.7);
        color: rgba(255, 255, 255, 0.7);
        & > * {
          transform: translate(1px, 1px);
        }
      }
    }
    &__icon {
      height: 30px;
      width: 30px;
      margin: 0 4px;
    }
    &__text {
      margin-right: 4px;
    }
  }
`;

const AddressBar = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 2px 2px;
  font-size: 11px;

  .ie__address_bar__title {
    line-height: 100%;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }

  .ie__address_bar__content {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    border: rgba(122, 122, 255, 0.6) 1px solid;
    height: 100%;
    margin: 0 1px;

    .ie__address_bar__ie_icon {
      width: 14px;
      height: 14px;
      margin: 0 1px 0 2px;
    }

    input {
      flex: 1;
      height: 100%;
      border: none;
      outline: none;
    }

    img {
      width: 15px;
      height: 15px;
      margin: 0 2px;
    }
  }

  .ie__address_bar__links {
    display: flex;
    align-items: center;
    padding: 0 6px 0 2px;
    height: 100%;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      width: 1px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.15);
    }
    span {
      color: rgba(0, 0, 0, 0.5);
    }
    img:first-child {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
    img:last-child {
      width: 8px;
      height: 8px;
    }
  }
`;

const IFrameWrapper = styled.div`
  flex: 1;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const Footer = styled.footer`
  height: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  .ie__footer__status {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 2px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
    span {
      font-size: 11px;
    }
  }
  .ie__footer__block {
    width: 22px;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ie__footer__right {
    display: flex;
    align-items: center;
    padding: 0 2px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    img {
      width: 16px;
      height: 16px;
      &:nth-child(2) {
        width: 18px;
        height: 18px;
      }
      &:last-child {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

const ObjectWrapper = styled.div`
  flex: 1;
  object {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default InternetExplorer;