import univ2Abi from 'assets/abis/univ2router.json';
import erc20Abi from 'assets/abis/erc20.json';
import { WETH } from './config';
// class helper that uses wagmi hooks to see if wallet is connected and if not then returns nothing but if it is then it has functions like get name get symbol get address of a token
import { useAccount, useConnect, useDisconnect } from 'wagmi'
// getContract

import { BigNumber, ethers,utils } from 'ethers';
// axios import 
import axios from 'axios';

export class Helper{
    constructor(etherv5provider){
        this.provider = etherv5provider;
        this.router = new ethers.Contract('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', univ2Abi, this.provider);
        this.weth = new ethers.Contract(WETH, erc20Abi, this.provider);
        this.wethData = {name: 'Wrapped Ether', symbol: 'WETH', address: WETH, decimals: 18};
        window.provider = this.provider;
        window.helper = this;
        window.ethers = ethers;
    }
    async getTokenDetails(address){
        const contract = new ethers.Contract(address, erc20Abi, this.provider);
        const name = await contract.name();
        const symbol = await contract.symbol();
        const decimals = await contract.decimals();
        return {name, symbol, address, decimals};
    }

    async getBalance(token,address = null){
        if (address === null || address === undefined || address === ''){
            address = await this.provider.getSigner().getAddress();
        }
        if (token.address === ''){
            console.log('no token');
            return (await this.provider.getBalance(address)) / 10 ** 18;
        }
        const contract = new ethers.Contract(token.address, erc20Abi, this.provider);
        const balance = await contract.balanceOf(address);
        return balance.toString() / 10 ** token.decimals;
    }

    async getChart(address){
        if (address == undefined){
            return <>No Chart Found for the Token...</>;
        }
        let pairAddress;
        if (address.address === ''){
            pairAddress = '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852';
        }else{
            let pairsURL = `https://api.dexscreener.com/latest/dex/search/?q=${address.address}`;
            let pairs = await axios.get(pairsURL);
            pairAddress = '0x4a25dbdf9629b1782c3e2c7de3bdce41f1c7f801';
            console.log(pairs);
            if (pairs.data.pairs.length === 0){
                return <>No Chart Found for the Token...</>;
            }

            for (let i = 0; i < pairs.data.pairs.length; i++){
                if (pairs.data.pairs[i].chainId === 'ethereum'){
                    pairAddress = pairs.data.pairs[i].pairAddress;
                    break;
                }
            }

            if (address.address === ''){
                pairAddress = '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852'
            }
            if (address.symbol == 'USDT'){  
                pairAddress = '0x4a25dbdf9629b1782c3e2c7de3bdce41f1c7f801';
            }
        }
        // {"schemaVersion":"1.0.0","pairs":[{"chainId":"ethereum","dexId":"uniswap","url":"https://dexscreener.com/ethereum/0x7f51fac5db38436d4c50b7c84fac593a477ef62c","pairAddress":"0x7f51FaC5dB38436D4C50B7C84fAc593a477Ef62C","labels":["v2"],"baseToken":{"address":"0xF768f474c6520dbc970646D819854fA2DbeD188A","name":"Brucey Kitty","symbol":"BKITTY"},"quoteToken":{"address":"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2","name":"Wrapped Ether","symbol":"WETH"},"priceNative":"0.0000006042","priceUsd":"0.001480","txns":{"m5":{"buys":0,"sells":0},"h1":{"buys":0,"sells":0},"h6":{"buys":0,"sells":0},"h24":{"buys":1,"sells":1}},"volume":{"h24":47.6,"h6":0,"h1":0,"m5":0},"priceChange":{"m5":0,"h1":0,"h6":0,"h24":-3.86},"liquidity":{"usd":2051.36,"base":692924,"quote":0.4186},"fdv":148022,"pairCreatedAt":1725061715000}]}
        // search for the first pair with chainId as ethereum and get me its address
        
        let iframe = <iframe src={`https://www.dextools.io/widget-chart/en/ether/pe-light/${pairAddress}?theme=light&chartType=2&chartResolution=30&drawingToolbars=false`} width="100%" height="100%" frameBorder="0"></iframe>;
        return iframe;
    }

    async getAmountsOut(tokenIn, tokenOut, amountIn, noParsing = false){
        if (tokenIn.address == ''){
            tokenIn = this.wethData;
        }
        if (tokenOut.address == ''){
            tokenOut = this.wethData;
        }
        console.log(tokenIn, tokenOut, amountIn);
        // convert amountIn from scientific notation to string eg it gives me 1.1111e+22
        amountIn = BigNumber.from(amountIn.toString()).toString();
        const amounts = await this.router.getAmountsOut(amountIn.toString(), [tokenIn.address, tokenOut.address]);
        // window.amounts = amounts;
        // return amounts[1].div(ethers.BigNumber.from(10).pow(tokenOut.decimals)).toString();
        if (noParsing){
            return amounts[1];
        }
        return utils.formatUnits(amounts[1], tokenOut.decimals);
    }

    async swapTokens(token1,token2,amountIn,slippage){
        let minAmountOut = 0
        let connectedRouter = this.router.connect(this.provider.getSigner());
        if (slippage <=99){
            let amountsOut = await this.getAmountsOut(token1, token2, amountIn, true);
            minAmountOut = amountsOut.mul(100 - slippage).div(100);
        }
        let tx;
        if (token1.address === '' || token2.address === '') {
            // in or out of eth
            if (token1.address === ''){
                // buy
                tx = await connectedRouter.swapExactETHForTokensSupportingFeeOnTransferTokens(minAmountOut, [this.wethData.address, token2.address], await this.provider.getSigner().getAddress(), Date.now() + 1000 * 60 * 10, {value: amountIn});
            } else {
                //sell
                let isApproved = await this.checkForApprovalonRouter(token1, amountIn);
                if (!isApproved){
                    await (await this.approveTokenForSpending(token1)).wait();
                }
                tx = await connectedRouter.swapExactTokensForETHSupportingFeeOnTransferTokens(amountIn, minAmountOut, [token1.address, this.wethData.address], await this.provider.getSigner().getAddress(), Date.now() + 1000 * 60 * 10);
            }
        }else{
            let isApproved = await this.checkForApprovalonRouter(token1, amountIn);
                if (!isApproved){
                    await (await this.approveTokenForSpending(token1)).wait();
                }
            tx = await connectedRouter.swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, minAmountOut, [token1.address, token2.address], await this.provider.getSigner().getAddress(), Date.now() + 1000 * 60 * 10);
        }
        window.tx = tx;
        return tx;
    }

    async approveTokenForSpending(token){
        if (token.address === ''){
            return;
        }
        const contract = new ethers.Contract(token.address, erc20Abi, this.provider.getSigner());
        return await contract.approve(this.router.address, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
    }

    async checkForApprovalonRouter(token, amount){
        console.log("Checking For approval : ",token, amount);
        if (token.address === ''){
            return true;
        }
        const contract = new ethers.Contract(token.address, erc20Abi, this.provider);
        const allowance = await contract.allowance(await this.provider.getSigner().getAddress(), this.router.address);
        return allowance.toString() / 10 ** token.decimals >= amount;
    }

    async getLatestPairsIn24Hours() {
        try {
            const response = await fetch('https://serverlesscors-one.vercel.app/api/cors?url=https://api.coinscan.com/api/v1/pair-explorer&origin=https://www.coinscan.com', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "timeframe": 24,
                "filters": {"network": [1]},
                "phrase": "",
                "rankings": [],
                "offset": 0,
                "limit": 50
              }),
            });
        
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
        
            const data = await response.json();
            console.log("Pairs: ", data);
            return data;
          } catch (error) {
            console.error('Error fetching pairs:', error);
            throw error;
          }
      }
}